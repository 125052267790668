/** @format */

import React, { useContext, useState } from "react";
import { ImGithub, ImLink } from "react-icons/im";
import { BiLogoPlayStore } from "react-icons/bi";
import { ReactComponent as FtLogoDark } from "./../assets/icons/42_Logo_dark.svg";
import { ReactComponent as FtLogoLight } from "./../assets/icons/42_Logo_light.svg";
import { ThemeContext } from "../contexts/themeContext";
import { IoIosClose } from "react-icons/io";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

function Project({ project }) {
	const { theme } = useContext(ThemeContext);
	const skill_size = project.skills.length;
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [isHovered, setIsHovered] = useState(false);

	const openModal = () => {
		setModalIsOpen(true);
		document.body.style.overflow = "hidden";
	};

	const closeModal = () => {
		setModalIsOpen(false);
		document.body.style.overflow = "auto";
	};

	const handleModalContentClick = (e) => {
		e.stopPropagation();
	};

	const renderers = {
		heading: ({ level, children }) => {
			switch (level) {
				case 1:
					return <h1 className="text-3xl font-bold">{children}</h1>;
				case 2:
					return <h2 className="text-2xl font-bold">{children}</h2>;
				// Add cases for other heading levels if needed
				default:
					return <h3>{children}</h3>;
			}
		}
	};

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<>
			<div className="flex flex-col gap-8">
				<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
					{isHovered ? (
						<video
							autoPlay={true}
							loop={true}
							src={project?.preview}
							alt={project.title}
							className="w-full aspect-[14/9] object-cover"
						/>
					) : (
						<img
							src={project.imageLink}
							alt={project.title}
							className="w-full aspect-[14/9] object-cover"
							onClick={() => openModal()}
						/>
					)}
				</div>

				<div className="flex flex-col gap-3">
					<div className="flex flex-row justify-between items-center">
						<h3 className="text-2xl">{project.title}</h3>
						<span className="flex flex-row gap-6">
							{project.ft_link && (
								<a href={project.ft_link}>
									{theme === "dark" ? (
										<FtLogoDark className="h-6 w-6" />
									) : (
										<FtLogoLight className="h-6 w-6" />
									)}
								</a>
							)}
							{project.githubLink && (
								<a href={project.githubLink}>
									<ImGithub size={24} />
								</a>
							)}
							{project.storeLink && (
								<a href={project.storeLink}>
									<BiLogoPlayStore size={24} />
								</a>
							)}
							{project.link && (
								<a href={project.link}>
									<ImLink size={22} />
								</a>
							)}
						</span>
					</div>
					<p className="text-base leading-6">{project.description}</p>
					<span className="text-base leading-6 flex flex-row flex-wrap text-[#6CACE4]">
						{project.skills.map((skill, index) => (
							<p className="whitespace-nowrap">
								{skill}
								{index < skill_size - 1 ? " -\u00A0" : ""}
							</p>
						))}
					</span>
				</div>
			</div>
			{modalIsOpen && (
				<div
					className="w-screen h-screen fixed top-0 left-0 bg-neutral-900/50 flex justify-center items-center font-mono z-50"
					onClick={closeModal}
				>
					<div
						className="max-w-screen-lg	mx-5 md:mx-10 xl:mx-0 w-full lg:w-[1024px] h-screen sm:h-5/6 lg:h-fit lg:aspect-video rounded-lg bg-neutral-700 overflow-hidden"
						onClick={handleModalContentClick}
					>
						<div className="w-full bg-neutral-800 py-2 px-4 flex justify-between items-center">
							<button
								className="aspect-square h-4 bg-red-500 rounded-full flex justify-center items-center"
								onClick={closeModal}
							>
								<IoIosClose className="text-neutral-700" />
							</button>
							<p>{project.title}</p>
							<div />
						</div>
						<div
							id="project-desc"
							className="w-full h-full pt-2 pb-20 px-4 overflow-auto"
						>
							<div className="w-full grid grid-cols-2 gap-2 bg-red-400">
								{project?.illustrations?.map((illustration, index) => {
									return (
										<img
											src={illustration}
											alt={project.title}
											className="w-1/2"
										/>
									);
								})}
							</div>
							<Markdown components={renderers} remarkPlugins={[remarkGfm]}>
								{project?.full_desc}
							</Markdown>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Project;
