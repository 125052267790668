import React from "react";

function Experience({
	title,
	status,
	company,
	location,
	startDate,
	endDate,
	tasks
}) {

	return <div className="w-full flex flex-col gap-2">
		<div className="flex justify-between items-center">
			<h3 className="text-2xl">{title} {status && `(${status})`}</h3>
			<span>
				<p className="hidden sm:block leading-6">{startDate} - {endDate}</p>
				<p className="block sm:hidden leading-6 text-nowrap">{startDate}</p>
				<p className="block sm:hidden leading-6 text-nowrap">{endDate}</p>
			</span>
		</div>
		<p className="text-[#6CACE4] leading-6">{company} / {location}</p>
		<ul className="custom-list list-inside leading-6 flex flex-col gap-2">
			{tasks.map((task, index) => <li key={index}>{task}</li>)}
		</ul>
	</div>;
}

export default Experience;
