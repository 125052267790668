import React, { useEffect, useState } from "react";
import "./../index.css";

const AnimatedCursor = () => {
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [followerPosition, setFollowerPosition] = useState({ x: 0, y: 0 });
	const [isElementHovered, setIsElementHovered] = useState(false);
	const [isTouchScreen, setIsTouchScreen] = useState(false);

	useEffect(() => {
		const delay = 100;

		const moveCursor = (e) => {
			setPosition({ x: e.clientX, y: e.clientY });
			setTimeout(() => {
				setFollowerPosition({ x: e.clientX, y: e.clientY });
			}, delay);
		};

		const handleElementHover = (e) => {
			setIsElementHovered(e.type === "mouseover");
		};

		const detectTouchScreen = () => {
			setIsTouchScreen("ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
		};

		detectTouchScreen();

		document.addEventListener("mousemove", moveCursor);

		document.querySelectorAll("a, button, img").forEach((el) => {
			el.addEventListener("mouseover", handleElementHover);
			el.addEventListener("mouseout", handleElementHover);
		});

		return () => {
			document.removeEventListener("mousemove", moveCursor);

			document.querySelectorAll("a, button, img").forEach((el) => {
				el.removeEventListener("mouseover", handleElementHover);
				el.removeEventListener("mouseout", handleElementHover);
			});
		};
	}, []);

	if (isTouchScreen) {
		return null; // Return null to hide the cursor on touchscreen devices
	}

	return (
		<>
			<div id="cursor-regular" style={{ top: position.y, left: position.x }} />
			<div
				id="cursor-follower"
				className={`${isElementHovered ? "follower-scale" : ""}`}
				style={{ top: followerPosition.y, left: followerPosition.x }}
			/>
		</>
	);
};

export default AnimatedCursor;
