/** @format */

import React, { useContext, useState } from "react";
import HeaderButton from "./HeaderButton";
import { PiSunFill, PiMoonFill } from "react-icons/pi";
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";

import { ThemeContext } from "../contexts/themeContext";
import scrollTo from "../functions/scrollTo";

function Header() {
	const { theme, toggleTheme } = useContext(ThemeContext);
	const [showMenu, setShowMenu] = useState(false);

	const openModal = () => {
		setShowMenu(true);
		document.body.style.overflow = "hidden";
	};

	const closeModal = () => {
		document.getElementById("mobile-menu").classList.remove("fade-in");
		document.getElementById("mobile-menu").classList.add("fade-out");
		setTimeout(() => {
			setShowMenu(false);
			document.body.style.overflow = "auto";
		}, 500);
	};

	return (
		<header className="flex justify-center items-center pt-5 sm:h-28">
			<div className="w-full flex justify-between items-center">
				<button
					className="text-2xl"
					onClick={() => {
						scrollTo("hero");
					}}
				>
					Camille Bernot
				</button>
				<button className="block sm:hidden" onClick={openModal}>
					<IoMenuOutline className="text-2xl" />
				</button>
				{showMenu ? (
					<div
						id="mobile-menu"
						className="absolute top-0 left-0 w-screen h-screen flex flex-col gap-20 backdrop-blur-xl p-5 fade-in z-10"
					>
						<span className="w-full h-28 items-center flex justify-end">
							<IoCloseOutline className="text-4xl" onClick={closeModal} />
						</span>
						<ul className="flex flex-col w-full h-full items-center text-2xl gap-8 font-Walsheim font-medium">
							<HeaderButton
								variant="mobile"
								title="Projects"
								action={() => {
									closeModal();
									scrollTo("projects");
								}}
							/>
							<HeaderButton
								variant="mobile"
								title="Experience"
								action={() => {
									closeModal();
									scrollTo("experience");
								}}
							/>
							<HeaderButton
								variant="mobile"
								title="Education"
								action={() => {
									closeModal();
									scrollTo("education");
								}}
							/>
							<HeaderButton
								variant="mobile"
								title="Contact"
								action={() => {
									closeModal();
									scrollTo("contact");
								}}
							/>
							<HeaderButton
								icon={
									theme === "dark" ? (
										<PiSunFill className="text-2xl" />
									) : (
										<PiMoonFill className="text-2xl" />
									)
								}
								action={toggleTheme}
							/>
						</ul>
					</div>
				) : null}
				<ul className="hidden text-lg sm:flex flex-row sm:gap-6 md:gap-12 items-center">
					<HeaderButton
						title="Projects"
						sectionId="projects"
						from="from-[#9845E8]"
						to="to-[#DD5789]"
						action={() => {
							scrollTo("projects");
						}}
					/>
					<HeaderButton
						title="Experience"
						sectionId="experience"
						from="from-[#9845E8]"
						to="to-[#DD5789]"
						action={() => {
							scrollTo("experience");
						}}
					/>
					<HeaderButton
						title="Education"
						sectionId="education"
						from="from-[#9845E8]"
						to="to-[#DD5789]"
						action={() => {
							scrollTo("education");
						}}
					/>
					<HeaderButton
						title="Contact"
						sectionId="contact"
						from="from-[#9845E8]"
						to="to-[#DD5789]"
						action={() => {
							scrollTo("contact");
						}}
					/>
					<HeaderButton
						from={theme === "dark" ? "from-[#FFFFFF]" : "from-[#000000]"}
						to={theme === "dark" ? "to-[#FFFFFF]" : "to-[#000000]"}
						icon={
							theme === "dark" ? (
								<PiSunFill className="text-2xl" />
							) : (
								<PiMoonFill className="text-2xl" />
							)
						}
						action={toggleTheme}
					/>
				</ul>
			</div>
		</header>
	);
}

export default Header;
