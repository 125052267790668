import React, {createContext, useState} from 'react'

const ThemeContext = createContext();

const getLocalTheme = () => {
	const localTheme = localStorage.getItem('theme');
	if (localTheme) {
		return localTheme;
	}
	const theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
	localStorage.setItem('theme', theme ? 'dark' : 'light');
	return (theme ? 'dark' : 'light');
}

const ThemeProvider = ({children}) => {
	const [theme, setTheme] = useState(getLocalTheme());
	const toggleTheme = () => {
		localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light');
		setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
		if (theme === "light") {
			document.body.style.backgroundColor = "#111111";
		}
		else {
			document.body.style.backgroundColor = "#F0F0F0";
		}
	}
	return (
		<ThemeContext.Provider value={{theme, toggleTheme}}>
			{children}
		</ThemeContext.Provider>
	)
}

export {ThemeContext, ThemeProvider};