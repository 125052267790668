/** @format */

import React from "react";

function HeaderButton({ title, from, to, icon, action, variant }) {
	if (variant === "mobile") {
		return (
			<li>
				<button onClick={action}>{title}</button>
			</li>
		);
	}
	return (
		<li className="relative group flex w-full">
			<div
				className={`absolute inset-0 bg-gradient-to-r ${from} ${to} rounded-2xl blur-xl transform opacity-0 group-hover:opacity-75 transition duration-1000 group-hover:duration-200`}
			/>
			<button
				className="relative flex justify-center items-center w-full rounded"
				onClick={action}
			>
				{title}
				{icon}
			</button>
		</li>
	);
}

export default HeaderButton;
