/** @format */

import React, { useContext, useEffect, useState } from "react";
import experiences from "./data/experiences.en";
import projects from "./data/projects.en";
import education from "./data/education.en";
import Experience from "./components/Experience";
import Project from "./components/Project";
import Divider from "./components/Divider";
import ContactButton from "./components/ContactButton";
import { SiGithub } from "react-icons/si";
import { MdOutlineEmail } from "react-icons/md";
import { RiLinkedinFill } from "react-icons/ri";
import scrollTo from "./functions/scrollTo";
import { ReactComponent as ArrowRightLight } from "./assets/icons/LongArrowRightLight.svg";
import { ReactComponent as ArrowRightDark } from "./assets/icons/LongArrowRightDark.svg";
import Header from "./components/Header";
import { ThemeContext } from "./contexts/themeContext";
import AnimatedCursor from "./lib/AnimatedCursor";

function App() {
	const [isSmallScreen, setIsSmallScreen] = useState(false);

	const { theme } = useContext(ThemeContext);
	if (theme === "light") {
		document.body.style.backgroundColor = "#F0F0F0";
	} else {
		document.body.style.backgroundColor = "#111111";
	}

	useEffect(() => {
		const mediaQuery = window.matchMedia("(max-width: 640px)");
		mediaQuery.addEventListener("change", handleMediaQueryChange);
		handleMediaQueryChange(mediaQuery);
		return () => {
			mediaQuery.removeEventListener("change", handleMediaQueryChange);
		};
	}, []);

	const handleMediaQueryChange = (mediaQuery) => {
		setIsSmallScreen(mediaQuery.matches);
	};

	return (
		<div
			className={`static px-5 md:px-10 xl:px-0 w-full min-h-screen ${
				theme === "dark" ? "text-[#F0F0F0]" : "text-[#1A1A1A]"
			} font-inter font-thin flex justify-center text-pretty`}
		>
			<AnimatedCursor />
			<div className="max-w-screen-lg flex flex-col gap-[80px] sm:gap-[120px]">
				<div className="flex flex-col gap-[40px]">
					<Header />
					<section id="hero">
						<p className="text-base">Hey, I'm</p>
						{isSmallScreen ? (
							<div className="mt-2 mb-6">
								<h1 className="text-6xl sm:text-8xl font-Walsheim font-medium bg-gradient-to-r from-[#9845E8] to-[#33D2FF] inline-block text-transparent bg-clip-text sm:tracking-wide">
									Camille
								</h1>
								<h1 className="text-6xl sm:text-8xl font-Walsheim font-medium bg-gradient-to-r from-[#33D2FF] to-[#DD5789] inline-block text-transparent bg-clip-text sm:tracking-wide">
									Bernot
								</h1>
							</div>
						) : (
							<h1 className="text-6xl sm:text-8xl font-Walsheim font-medium bg-gradient-to-r from-[#9845E8] via-[#33D2FF] to-[#DD5789] inline-block text-transparent bg-clip-text mt-5 mb-6 sm:tracking-wide">
								Camille Bernot
							</h1>
						)}
						<p className="text-justify font-light text-lg leading-6 mb-10">
							Hello! I'm a 23-year-old developer based in Lyon, France, studying
							at 42 school and working for a cool small company where I get to
							wear many hats. I'm passionate about web, app development and
							finding solutions. Always on the hunt for chances to expand my
							knowledge and skills, I welcome new opportunities with open arms.
							If you have an exciting project or an opportunity to learn and
							grow together, don't hesitate to reach out to me!
						</p>
						<button
							className="group leading-6 font-light text-2xl flex w-fit items-center justify-center gap-3"
							onClick={() => {
								scrollTo("contact");
							}}
						>
							<p>Say hi</p>
							{theme === "dark" ? (
								<ArrowRightDark className="transition-transform duration-500 ease-in-out transform group-hover:translate-x-2 z-0" />
							) : (
								<ArrowRightLight className="transition-transform duration-500 ease-in-out transform group-hover:translate-x-2 z-0" />
							)}
						</button>
					</section>
				</div>

				<section
					id="experience"
					className="flex flex-col sm:flex-row gap-6 sm:gap-0"
				>
					<h2 className="basis-1/4 uppercase font-light text-lg">Experience</h2>
					<div className="basis-3/4 w-full flex flex-col gap-7 sm:gap-14">
						{experiences.map((experience, index) => {
							return <Experience key={"exp-" + index} {...experience} />;
						})}
					</div>
				</section>

				<Divider />

				<section id="projects" className="flex flex-col gap-6 sm:gap-14">
					<h2 className="uppercase font-light text-lg">Featured Projects</h2>

					<div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-14">
						{projects.map((project, index) => {
							return <Project key={"project-" + index} project={project} />;
						})}
					</div>
				</section>

				<Divider />

				<section
					id="education"
					className="flex flex-col sm:flex-row gap-6 sm:gap-0"
				>
					<h2 className="basis-1/4 uppercase font-light text-lg">Education</h2>
					<div className="basis-3/4 w-full flex flex-col gap-7 sm:gap-14">
						{education.map((education, index) => {
							return <Experience key={"ed-" + index} {...education} />;
						})}
					</div>
				</section>

				<Divider />

				<section
					id="contact"
					className="flex flex-col sm:flex-row gap-6 sm:gap-0"
				>
					<h2 className="basis-1/4 uppercase font-light text-lg">Contact</h2>
					<div className="basis-3/4 w-full flex flex-row items-center justify-between gap-3.5">
						<ContactButton
							title={isSmallScreen ? "Email" : "Send an email"}
							icon={<MdOutlineEmail size={24} />}
							link="mailto:camil.bernot.cb@gmail.com"
							from="from-[#9845E8]"
							to="to-[#DD5789]"
						/>
						<ContactButton
							className="bg-[#aaaaaa]"
							title={"Linkedin"}
							icon={<RiLinkedinFill size={24} />}
							link="https://www.linkedin.com/in/camillebernot/"
							from="from-[#1e66c2]"
							to="to-[#59a7ff]"
						/>
						<ContactButton
							title={"GitHub"}
							icon={<SiGithub size={24} />}
							link="https://www.github.com/RhesusP/"
							from="from-[#aaaaaa]"
							to="to-[#aaaaaa]"
						/>
					</div>
				</section>

				<footer className="flex justify-center">
					{/* <p>Camille Bernot - 2024</p> */}
				</footer>
			</div>
		</div>
	);
}

export default App;
