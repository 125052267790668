import React, { useContext } from "react";
import { ThemeContext } from "../contexts/themeContext";

function Divider() {
	const {theme} = useContext(ThemeContext);
	console.log("[Divider] theme:", theme);
	return <span className={`flex border-t-[0.5px] ${theme === 'dark' ? "border-[#C6C6C6] opacity-50" : "border-[#111111] opacity-100 z-0"}`} />;
}

export default Divider;
