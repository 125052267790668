const scrollTo = (id) => {
	var element = document.getElementById(id);
	const offset = 50;
	const elementPosition = element.getBoundingClientRect().top;
	const offsetPosition = elementPosition - offset;
	window.scrollTo({
		top: offsetPosition,
		behavior: "smooth"
	});
};

export default scrollTo;