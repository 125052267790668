import React, { useContext } from "react";
import { ThemeContext } from "../contexts/themeContext";

function ContactButton({ title, icon, link, from, to }) {
	const  {theme} = useContext(ThemeContext);
	return (
		<div class="relative group flex w-full">
			<div class={`absolute inset-0 bg-gradient-to-r ${from} ${to} rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200`} />
			<a
				href={link}
				className={`relative flex flex-row justify-center items-center gap-2 w-full py-3 border-[1px] border-[#C6C6C6] border-opacity-75 ${theme === 'dark' ? "bg-[#111111]" : "bg-[#F0F0F0]"} rounded`}
			>
				{icon}
				<p className="text-base leading-6">{title}</p>
			</a>
		</div>
	);
}

export default ContactButton;
